//--------------------------
// VARIABLES
//--------------------------

/* FONTS */

@import url('https://fonts.googleapis.com/css?family=Lato:400,300,700|Open+Sans:400,300,600');
@import url('https://use.fontawesome.com/releases/v5.2.0/css/all.css');
@import url('/assets/fonts/TruenoRg.otf');
@font-face {
  font-family: 'Trueno';
  src: url(../fonts/TruenoRg.otf); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'TruenoBold';
  src: url(./fonts/TruenoBd.otf); /* IE9 Compat Modes */
}
/* GENERAL */

// breakpoints
$base-width-max: 1200px;
$narrow-width-max: 768px;
$small-width-max: 480px;
$xl-width-min: 1600px;
$base-spacing: 0px;
$narrow-spacing: 0px;
$xl-spacing: 0px;
$base-padding: 15px 30px;
$narrow-padding: 10px 20px;
$xl-padding: 20px 40px;

// Border radius
$border-radius-base: 0px !default;
$border-radius-large: 0px !default;
$border-radius-small: 0px !default;

// Padding
$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;

$padding-base-vertical: 12px !default;
$padding-base-horizontal: 24px !default;

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;

// Line height
$line-height-large: 2.5 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;

// Font sizes
$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default;
$font-size-small: ceil(($font-size-base * 0.85)) !default;

/* COLORS */

// Main theme colors
$theme-primary-color:#000 !default;
$theme-secondary-color: #f4f4f4 !default;
$theme-blue-color: #464CE9 !default;
$theme-success-color: #A1E858 !default;
$theme-danger-color: #D0021B !default;
$theme-info-color: #075EC4 !default;
$theme-warning-color: #FFD400 !default;
$theme-blue-color: #2583c5 !default;
$theme-gray-color: #BBB8B3 !default;
$theme-dark-gray-color: #3b3b3a !default;
// Text
$theme-text-color: #000 !default;
$theme-off-white-color: #F3F4F4 !default;

// Banners
$theme-dark-banner-color: #3B3B3A !default;

// Backgrounds
$theme-gray-gradient: linear-gradient(to bottom, #fff, #eee) !default;
$theme-dark-bg: #333 !default;
$theme-nav-toggler-icon-svg: "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(251, 168, 25, 1)' stroke-width='4' stroke-linecap='square' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E";
$gradient-bg: linear-gradient(160deg, #394dee, #7344d5 33%, #913fc9 49%, #c737b2);
/* INPUTS */
$input-border-radius: 3px !default;
$input-drop-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.5) !default;
$input-border: solid 1px #dddddd !default;
$input-placeholder-color: rgba(0, 0, 0, 0.5) !default;

// from jekyll sitefavicon.png

$heading-font: 'Trueno'; /** h1, h2, h3, h4, h5 **/
$heading-font-weight: 600;
$heading-font-color: #000;

$subheading-font: 'Trueno'; /** h3, h4, h5 **/
$subheading-font-weight: 400;
// $subheading-font-color: #047ac7;
$subheading-font-color: #333;

$primary-font: 'open-sans'; /** body, p **/
$primary-font-weight: 300;
$primary-font-color: #000;

$link-color: #464CE9;
$link-weight: 500;
$link-hover-color: #333;
$link-hover-weight: null;

$nav: #fff;
$nav-hover: null;
$nav-font-color: #000;
$nav-hover-font-color: #333;

$footer: #000;
$footer-font-color: #fff;
$footer-link-color: #fff;
$footer-link-hover-color: null;
$subfooter-bg: #000;
$subfooter-font-color: #D8D8D8;
$subfooter-link-color: #D8D8D8;
$footer-icon-color: null;
$footer-border: none;

/***** section settings and colors ****/

$section-padding: 90px 20px;
$section-padding-mobile: 60px 0px;
$content-container-bg: null;

$section-font-color: null;
$section-heading-font-color: null;
$section-subheading-font-color: null;
$section-link-color: null;
$section-link-hover-color: null;
$section-bg: #fff;

$section-bgclear-font-color: #fff;
$section-bgclear-heading-font-color: null;
$section-bgclear-subheading-font-color: null;
$section-bgclear-link-color: null;
$section-bgclear-link-hover-color: null;

$section-alt-font-color: #000;
$section-alt-subheading-font-color: null;
$section-alt-heading-font-color: null;
$section-alt-link-color: null;
$section-alt-link-hover-color: null;
$section-alt-bg: #ccc;

$section-dark-font-color: #fff;
$section-dark-heading-font-color: #eee;
$section-dark-subheading-font-color: #fff;
$section-dark-link-color: null;
$section-dark-link-hover-color: null;
$section-dark-bg: #333;
$section-dark-bg-grad: linear-gradient(88deg, #333, #efefef);

$section-grey-font-color: #fff;
$section-grey-heading-font-color: #eee;
$section-grey-subheading-font-color: null;
$section-grey-link-color: null;
$section-grey-link-hover-color: null;
$section-grey-bg: #efefef;

/***** buttons ****/

$btn-font-color: #fff;
$radius: 0;
$default: #902fd1;
$default-hover: #902fd1;
$default-font-color: null;
$pink: #c737b2;
$pink-hover: #c737b2;
$pink-font-color: null;
$info: #4a40b1;
$info-hover: #4a40b1;
$info-font-color: null;
$success: #b8e986;
$success-hover: #b8e986;
$success-font-color: #fff;
$warning: null;
$warning-hover: null;
$warning-font-color: null;
// Font
$btn-font-weight: bold !default;

// GENERAL BUTTONS

$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;

$btn-success-color: #fff !default;
$btn-success-bg: $theme-success-color !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;

$btn-info-color: #fff !default;
$btn-info-bg: $theme-info-color !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $theme-warning-color !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $theme-danger-color !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color: $theme-gray-color !default;

/***** form styles ****/

$form-headline-color: null;
$form-background-color: null;
$form-border: null;
$form-box-shadow: null;

$mediatint-color: #65c4dc;
$bgoverlayDark: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
$bgoverlayLight: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.9));
